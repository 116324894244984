require("./hero-banner.scss");

(function($, trackUrl) {
    if (!trackUrl) return;

    $(".hero-banner-bottom-link").click(function(ev) {
        var trackImg = document.createElement("img");
        trackImg.width = 1;
        trackImg.height = 1;
        trackImg.border = 0;
        trackImg.src = trackUrl;
        this.parentElement.appendChild(trackImg);
    });
})(jQuery, bannerImpressionUrl);
